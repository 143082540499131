<template />

<script>
import { mapState } from 'vuex';

export default {
  name: 'Logout',
  data() {
    return {

    };
  },
  computed: mapState({
    cohortsStore: state => state.cohortsStore,
    departmentsStore: state => state.departmentsStore,
    dissertationsStore: state => state.dissertationsStore,
    emailsStore: state => state.emailsStore,
    faqsStore: state => state.faqsStore,
    fellowshipsStore: state => state.fellowshipsStore,
    helpTopicsStore: state => state.helpTopicsStore,
    institutionsStore: state => state.institutionsStore,
    jurisdictionsStore: state => state.jurisdictionsStore,
    messagesStore: state => state.messagesStore,
    navigationStore: state => state.navigationStore,
    outcomesStore: state => state.outcomesStore,
    participantsStore: state => state.participantsStore,
    patentsStore: state => state.patentsStore,
    presentationsStore: state => state.presentationsStore,
    progressStore: state => state.progressStore,
    projectsStore:  state => state.projectsStore,
    publicationsStore: state => state.publicationsStore,
    usersStore: state => state.usersStore,
    valueListsStore: state => state.valueListsStore
  }),
  created() {
    this.handleLogout();
  },
  methods: {
    /* ----------Logout----------*/
    handleLogout() {
      this.$store.dispatch('cohortsStore/clearStore');
      this.$store.dispatch('departmentsStore/clearStore');
      this.$store.dispatch('dissertationsStore/clearStore');
      this.$store.dispatch('emailsStore/clearStore');
      this.$store.dispatch('faqsStore/clearStore');
      this.$store.dispatch('fellowshipsStore/clearStore');
      this.$store.dispatch('fundingsStore/clearStore');
      this.$store.dispatch('helpTopicsStore/clearStore');
      this.$store.dispatch('institutionsStore/clearStore');
      this.$store.dispatch('jurisdictionsStore/clearStore');
      this.$store.dispatch('messagesStore/clearStore');
      this.$store.dispatch('navigationStore/clearStore');
      this.$store.dispatch('outcomesStore/clearStore');
      this.$store.dispatch('participantsStore/clearStore');
      this.$store.dispatch('patentsStore/clearStore');
      this.$store.dispatch('presentationsStore/clearStore');
      this.$store.dispatch('progressStore/clearStore');
      this.$store.dispatch('projectsStore/clearStore');
      this.$store.dispatch('publicationsStore/clearStore');
      this.$store.dispatch('usersStore/clearStore');
      this.$store.dispatch('valueListsStore/clearStore');
      this.$store.dispatch('usersStore/setAdminMode', 0);

      window.localStorage.removeItem('activeProject');
      window.localStorage.removeItem('appMode');
      window.localStorage.removeItem('adminMode');
      window.localStorage.removeItem('activeUser');
      window.localStorage.removeItem('activeUserType');
      window.localStorage.removeItem('authUser');
      window.localStorage.removeItem('authUserType');
      window.localStorage.removeItem('timer');

      /* Send user to index */
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>

</style>
